import { forwardRef, useMemo, useState } from 'react'
import type { MutableRefObject } from 'react'
import clsx from 'clsx'

import { isNotEmptyArray } from 'utils/client'
import VerticalCard from 'ui/VerticalCard'
import Pagination from '@/components/Pagination'
import { HorizontalCard, DynamicBackground, HeaderContent } from 'ui'
import { getTabItems, getTabNames } from 'utils/tabs'
import type { PageDocumentDataBodyImageCardGridSlice } from 'types/types.generated'

// This component is used as a slice in Prismic CMS and renders the Blog content

const ImageCardGrid = forwardRef(
	(
		{
			primary,
			items,
			pagination,
			className = '',
			globalData
		}: PageDocumentDataBodyImageCardGridSlice,
		ref: MutableRefObject<HTMLDivElement>
	) => {
		const {
			tab_cards,
			header_centered,
			section_heading,
			heading_max_width,
			section_description,
			description_max_width,
			section_cta_label,
			section_cta_link,
			cta_with_arrow,
			secondary_cta_label,
			secondary_cta_link,
			secondary_cta_with_arrow,
			text_contrast_mode,
			background_color,
			background_squeegee_options,
			background_height,
			columns
		} = primary || {}

		const [currentPage, setCurrentPage] = useState(1)

		const paginate = (data, pageNumber, pageSize) => {
			const startIndex = (pageNumber - 1) * pageSize
			return [...data].reverse().slice(startIndex, startIndex + pageSize)
		}

		const paginatedPosts = paginate(items, currentPage, 12)

		const handleOnPageChange = (page) => {
			setCurrentPage(page)
		}

		const [selectedIndex, setSelectedIndex] = useState(0)

		const tabNames = useMemo(() => {
			return getTabNames(tab_cards, items)
		}, [items, tab_cards])

		const tabItems = useMemo(() => {
			return getTabItems(tab_cards, items, tabNames)
		}, [items, tabNames, tab_cards])

		const backgroundColor = background_color || 'white'

		let verticalCardSizes = ''
		// subtracting page px and gap-x's to calculate true image width
		if (columns == 2) {
			verticalCardSizes =
				'(max-width: 640px) calc(100vw - 32px), (max-width: 768px) calc(50vw - 32px - 16px), (max-width: 1024px) calc(50vw - 48px - 16px), (max-width: 1280px) calc(50vw - 80px - 16px), (max-width: 1728px) calc(50vw - 80px - 16px), 784px'
		} else if (columns == 3) {
			verticalCardSizes =
				'(max-width: 640px) calc(100vw - 32px), (max-width: 768px) calc(33vw - 32px - 32px), (max-width: 1024px) calc(33vw - 48px - 32px), (max-width: 1280px) calc(33vw - 80px - 32px), (max-width: 1728px) calc(33vw - 80px - 32px), 522px'
		}

		return (
			<section
				className={`image-card-grid relative section py-10 lg:py-32
					${text_contrast_mode === 'light' ? 'text-white' : 'text-black-rt'}
					${className}
					remove-space-if-in-rich-text
				`}
				ref={ref}
			>
				<DynamicBackground
					background_color={backgroundColor}
					backgroundHeight={background_height}
					backgroundSqueegeeOption={background_squeegee_options}
				/>

				<div className="container">
					{/* Title, description, CTAs */}
					<HeaderContent
						title={section_heading}
						titleMaxWidth={heading_max_width}
						description={section_description}
						descriptionMaxWidth={description_max_width}
						primaryCta={{
							label: section_cta_label,
							link: section_cta_link,
							withArrow: cta_with_arrow
						}}
						secondaryCta={{
							label: secondary_cta_label,
							link: secondary_cta_link,
							withArrow: secondary_cta_with_arrow
						}}
						textContrast={text_contrast_mode}
						centered={header_centered}
						globalData={globalData}
					/>

					{/* Tab Cards */}
					{tabNames ? (
						<>
							<div className="top-tabs-container pb-10" suppressHydrationWarning>
								{tabNames.map((item, i) => {
									const isSelected = i === selectedIndex

									// Tab title
									return item ? (
										<button
											className={clsx(
												'top-tab',
												isSelected && 'is-active',
												text_contrast_mode === 'dark' && 'dark'
											)}
											onClick={() => setSelectedIndex(i)}
											suppressHydrationWarning
											key={i}
										>
											{item}
										</button>
									) : null
								})}
								<hr className="w-full -translate-y-[1px]" />
							</div>
							{isNotEmptyArray(tabItems) ? (
								<div
									className={`grid grid-cols-1 ${
										columns === '3' ? 'sm:grid-cols-3  gap-x-4' : 'sm:grid-cols-2 gap-x-8'
									} gap-y-10 lg:gap-y-24`}
									suppressHydrationWarning
								>
									{/* Cards in Tabs */}
									{tabItems.map((items, i) => {
										const isSelected = i === selectedIndex

										return items.map((resourceObj: any, i) => {
											return isSelected ? (
												resourceObj.card_orientation === 'horizontal' ? (
													<HorizontalCard key={i} resourceObj={resourceObj} roundedImage />
												) : (
													<VerticalCard
														key={i}
														resourceObj={resourceObj}
														largeImage
														sizes={verticalCardSizes}
													/>
												)
											) : null
										})
									})}
								</div>
							) : null}
						</>
					) : // Regular Cards
					isNotEmptyArray(items) ? (
						<div
							className={`grid grid-cols-1 ${
								columns === '3' ? 'sm:grid-cols-2 lg:grid-cols-3 gap-x-4' : 'sm:grid-cols-2 gap-x-8'
							} gap-y-12 lg:gap-y-24`}
						>
							{/* Cards */}
							{pagination
								? paginatedPosts.reverse().map((resourceObj, i) => {
										return (
											<VerticalCard
												key={i}
												resourceObj={{ background_color: 'transparent', ...resourceObj }}
												largeImage
												sizes={verticalCardSizes}
											/>
										)
								  })
								: items.map((resourceObj, i) => {
										if (resourceObj.card_orientation === 'horizontal') {
											return (
												<HorizontalCard
													key={i}
													resourceObj={{ background_color: 'transparent', ...resourceObj }}
													roundedImage
												/>
											)
										} else {
											return (
												<VerticalCard
													key={i}
													resourceObj={resourceObj}
													largeImage
													sizes={verticalCardSizes}
												/>
											)
										}
								  })}
						</div>
					) : null}

					{/* Pagination */}
					{pagination ? (
						<Pagination
							items={items.length}
							pageSize={12}
							currentPage={currentPage}
							onPageChange={handleOnPageChange}
						/>
					) : null}
				</div>
			</section>
		)
	}
)
ImageCardGrid.displayName = 'ImageCardGrid'

export default ImageCardGrid
