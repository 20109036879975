import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import { Icon } from 'ui'

type Props = {
	items: number
	pageSize: number
	currentPage: number
	onPageChange: (_page: number) => void
}

export default function Pagination({ items, pageSize, currentPage, onPageChange }: Props) {
	const totalPages = Math.ceil(items / pageSize)

	const handleClick = (pageNumber) => {
		onPageChange(pageNumber)
	}

	const buttonClasses =
		'font-medium text-sm border border-g-200 rounded-lg w-10 h-10 flex items-center justify-center pt-1 hover:bg-g-100 transition-colors duration-300 ease-in-out'

	const renderPageNumbers = () => {
		if (totalPages === 1) return null

		const pageNumbers = []
		const visiblePages = 3 // Number of visible page numbers

		// Generate page numbers
		for (let i = 1; i <= totalPages; i++) {
			pageNumbers.push(i)
		}

		const buttonSelectedClass = 'bg-black-rt text-white hover:bg-black-rt border-black-rt'

		// Render page numbers with dots
		if (totalPages <= visiblePages) {
			return pageNumbers.map((number, i) => (
				<button
					key={i}
					className={`
						${buttonClasses}
						${currentPage === number ? ` ${buttonSelectedClass}` : ``}
					`}
					onClick={() => handleClick(number)}
				>
					{number}
				</button>
			))
		} else {
			const firstPageNumber = Math.max(1, currentPage - Math.floor(visiblePages / 2))
			const lastPageNumber = Math.min(totalPages, firstPageNumber + visiblePages - 1)

			const firstPage = (
				<button
					key={1}
					className={`
							${buttonClasses}
							${currentPage === 1 ? `${buttonSelectedClass}` : ''}
						`}
					onClick={() => handleClick(1)}
				>
					1
				</button>
			)

			const lastPage = (
				<button
					key={totalPages}
					className={`
							${buttonClasses}
							${currentPage === totalPages ? `` : ''}
						`}
					onClick={() => handleClick(totalPages)}
				>
					{totalPages}
				</button>
			)

			const pagesToDisplay = []

			if (firstPageNumber > 1) {
				pagesToDisplay.push(firstPage)
				if (firstPageNumber > 2) {
					pagesToDisplay.push(
						<span key="prev-dots" className="text-xl ">
							...
						</span>
					)
				}
			}

			for (let i = firstPageNumber; i <= lastPageNumber; i++) {
				pagesToDisplay.push(
					<button
						key={i}
						className={`
							${buttonClasses}
							${currentPage === i ? `${buttonSelectedClass}` : ''}
						`}
						onClick={() => handleClick(i)}
					>
						{i}
					</button>
				)
			}

			if (lastPageNumber < totalPages) {
				if (lastPageNumber < totalPages - 1) {
					pagesToDisplay.push(
						<span key="next-dots" className="text-xl">
							...
						</span>
					)
				}
				pagesToDisplay.push(lastPage)
			}

			return pagesToDisplay
		}
	}

	return (
		<div className="w-full text-center py-16">
			<div className="flex gap-2 justify-center items-center">
				{currentPage > 1 ? (
					<button
						className={buttonClasses}
						onClick={() => onPageChange(currentPage - 1)}
						aria-label="Go to previous page"
					>
						<Icon icon={faChevronLeft} className="mb-1" />
					</button>
				) : null}
				{renderPageNumbers()}

				{currentPage < totalPages ? (
					<button
						className={buttonClasses}
						onClick={() => onPageChange(currentPage + 1)}
						aria-label="Go to next page"
					>
						<Icon icon={faChevronRight} className="mb-1" />
					</button>
				) : null}
			</div>
		</div>
	)
}
